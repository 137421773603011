
<template>
<div id="main">
    <div v-if="message">{{message}}</div> 
    <div v-if="ready" >
        <div v-if="editmode==false" >
          <div class="datahtml" v-html="html"></div>          
          <button v-if="int_editable=='true'" @click="editer()">Editer</button>
        </div>
        <div v-else>
            <editor v-model="html" :urlimg="'/api/?type='+rep+'&num='+num+'&images=y'"/>
            <button @click="enregistrer()">Enregistrer</button>

            <div style="margin-top:40px">
              <h3>Pévisualiser</h3>
              <div v-html="html"></div>
            </div>
        </div>
    </div>
</div>        
</template>

<script>
import axios from 'axios';
import Editor from '@/components/Tiptap.vue'

export default {
  name: 'Article',

  components : {Editor},

  props: {
    num:String,
    rep:String,
    editable:String
  },

  data() {
      return {
          html: "",
          ready: false,
          message: "Chargement...",
          editmode : false,
          int_editable : false,
      }
  },

  created : function() {
      this.lire();
      this.isEditable();
  },

  methods: {
    isEditable() {
        var baseurl = "/api/";
        this.int_editable=false;
        
        axios.get(baseurl+'?islogged').then(response =>{
            if (response.data==1) {
                this.int_editable=this.editable;
            }
            
        }).catch(error => {
            console.log(error);
            
        })
    },

    lire() {
      var baseurl = "/api/"
      //  var baseurl = "/data"
      axios.get(baseurl+'?type='+this.rep+'&num='+this.num).then(response =>{
        this.html = response.data; 
        this.ready=true;
        this.message="";
      }).catch(error => {
          this.message=error;
          this.ready=true;
      })


    },

    enregistrer() {
        this.editmode=false;
      
        var baseurl = "/api/"
        //  var baseurl = "/data"
        axios.post(baseurl,{
          type:this.rep,
          num:this.num,
          texte:this.html
        }).then(response =>{
          this.message = response.data; 
          this.ready=true;     
      }).catch(error => {
          this.message=error;
          this.ready=true;
      })


      },

    editer() {
        this.editmode=true;
        this.message="";
      }
  }

}

</script> 
<style scoped>
	.datahtml {
		display:block;
		margin-left: auto;
		margin-right: auto;
		width:100%;
		height:auto;		
	}

/* Gazette */
    .gazette img {
		display:block;
		margin-left: auto;
		margin-right: auto;
	}

	.gazette .date {
			text-align: center;
			display: block;
			font-size: 1rem;
			height: 1;
			margin: 0 0 1rem 0;
			position: relative;
	}


</style>