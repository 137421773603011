<template>
<div class="tiptapeditor">
  <div v-if="editor" class="menuediteur">
    <input
      type="color"
      @input="editor.chain().focus().setColor($event.target.value).run()"
      :value="editor.getAttributes('textStyle').color" >
    <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
      <img src="@/assets/icons/bold24.png"/>
    </button>
    <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
      <img src="@/assets/icons/italic24.png" />
    </button>
    <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
      <img src="@/assets/icons/strikethrough24.png" />
    </button>
    <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
      <img src="@/assets/icons/paragraph24.png" />
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
      <img src="@/assets/icons/h-124.png" />
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
      <img src="@/assets/icons/h-224.png" />
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
      <img src="@/assets/icons/h-324.png" />
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
      <img src="@/assets/icons/h-424.png" />
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
      <img src="@/assets/icons/h-524.png" />
    </button>
    <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
      <img src="@/assets/icons/list-unordered24.png" />
    </button>
    <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
      <img src="@/assets/icons/list-ordered24.png" />
    </button>
    <button @click="editor.chain().focus().setHorizontalRule().run()">
      <img src="@/assets/icons/separator24.png" />
    </button>
    <button @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
      <img src="@/assets/icons/align-left24.png" />
    </button>
    <button @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
      <img src="@/assets/icons/align-center24.png" />
    </button>
    <button @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
      <img src="@/assets/icons/align-right24.png" />
    </button>
    <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
      <img src="@/assets/icons/align-justify24.png" />
    </button>    
    <button @click="onImageSelect()">
      <img src="@/assets/icons/image-2-fill24.png" alt=""/>
    </button>
    <button @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
      <img src="@/assets/icons/table-224.png" alt="Ajouter un tableau"/>
    </button>
    <button @click="editor.chain().focus().addColumnBefore().run()" :disabled="!editor.can().addColumnBefore()">
      <img src="@/assets/icons/insert-column-left24.png"/>
    </button>
    <button @click="editor.chain().focus().addColumnAfter().run()" :disabled="!editor.can().addColumnAfter()">
      <img src="@/assets/icons/insert-column-right24.png"/>
    </button>
    <button @click="editor.chain().focus().deleteColumn().run()" :disabled="!editor.can().deleteColumn()">
      <img src="@/assets/icons/delete-column24.png"/>
    </button>
    <button @click="editor.chain().focus().addRowBefore().run()" :disabled="!editor.can().addRowBefore()">
      <img src="@/assets/icons/insert-row-top24.png"/>
    </button>
    <button @click="editor.chain().focus().addRowAfter().run()" :disabled="!editor.can().addRowAfter()">
      <img src="@/assets/icons/insert-row-bottom24.png"/>
    </button>
    <button @click="editor.chain().focus().deleteRow().run()" :disabled="!editor.can().deleteRow()">
      <img src="@/assets/icons/delete-row24.png"/>
    </button>
    <button @click="editor.chain().focus().deleteTable().run()" :disabled="!editor.can().deleteTable()">
      Supp
    </button>
    <button @click="editor.chain().focus().mergeCells().run()" :disabled="!editor.can().mergeCells()">
      <img src="@/assets/icons/merge-cells-horizontal24.png" alt="Fusionner cellules"/>
    </button>
    <button @click="editor.chain().focus().splitCell().run()" :disabled="!editor.can().splitCell()">
      <img src="@/assets/icons/split-cells-horizontal24.png" alt="scinder cellules"/>
    </button>    
    <button @click="editor.chain().focus().undo().run()">
      <img src="@/assets/icons/arrow-go-back-line24.png" />
    </button>
    <button @click="editor.chain().focus().redo().run()">
      <img src="@/assets/icons/arrow-go-forward-line24.png" />
    </button>
  </div>
  <div class="editeur">
    <editor-content v-if="!displayimages" class="textedit" :editor="editor" />
    <ul v-else class="imageedit">
      <li v-for="item in imagelist" :key="item" >  
        <button @click="editor.chain().focus().setImage({ src: item }).run();" ><img src="@/assets/icons/image-add-fill24.png" /></button>
        <img  :src="item" />
      </li>
    </ul>
  </div>
</div>
</template>


<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

import axios from 'axios';


export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    urlimg : String,
  },

  data() {
    return {
      editor: null,
      imagelist : [],
      displayimages : false
    }
  },

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  methods:{
      listimages() {
        axios.get(this.urlimg).then(response =>{
          this.imagelist = response.data; 
        }).catch(() => {

        })
      },
  
      onImageSelect() {
        this.displayimages = !this.displayimages;
      }

  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Image,
        Dropcursor,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Color,
      ],
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML())

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
    this.listimages();
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68CEF8;
    }
  }
}

.content {
  padding: 1rem 0 0;

  h3 {
    margin: 1rem 0 0.5rem;
  }

  pre {
    border-radius: 5px;
    color: #333;
  }

  code {
    display: block;
    white-space: pre-wrap;
    font-size: 0.8rem;
    padding: 0.75rem 1rem;
    background-color:#e9ecef;
    color: #495057;
  }
}
.textedit {
  background-color : white;
  border-color : black;  
}
.textedit * {
  margin-bottom : 0.5rem;
}


button {
  border-color : transparent;
  padding : 0 1rem;
}

button:focus {
  background-color : green;
}

button.is-active {
  background-color : green;
}


.textedit {
  width : 100%;
}

.textedit table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
}
.textedit td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  

.textedit .tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.textedit .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.imageedit {
  display: flex;
	list-style: none;
	flex-wrap: wrap;
	padding: 0;
  background-color:#adf;
  width: 100%;
}

.imageedit li {
	width: 30%;
	//margin: 1rem 2.5%;
	position: relative;
	aspect-ratio: 1/1;
	overflow: hidden;
}

.imageedit img {
		width: 50%;
		height: 50%;
		display: block;
		object-fit: cover;
}
.imageedit button {
	/*	--size: 2px;
		position: absolute;
		line-height: var(--size);
		height: var(--size);
		border-radius: var(--size);
		box-shadow: 0 0 5px currentColor;
		right: 10rem;
		appearance: none;*/
		border: 0;
		padding: 0;

}


</style>